import React, { lazy, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Index from "../components/Index";

import FastoCaseStudy from "../components/FastoCaseStudy";
import HuddleHeartCaseStudy from "../components/HuddleHeartCaseStudy";
import PlywoodBazarCaseStudy from "../components/PlywoodBazarCaseStudy";
import ROCareIndiaCaseStudy from "../components/ROCareIndiaCaseStudy";
import NikkyPoreCaseStudy from "../components/NikkyPoreCaseStudy";
import DeyorCaseStudy from "../components/DeyorCaseStudy";
import SamagriCaseStudy from "../components/SamagriCaseStudy";
import EbslonLandingPageUpdate from "../components/EbslonLandingPage/EbslonLandingPageUpdate";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import ScrollToTop from "../components/Utility/ScrollToTop";
import { Toaster } from "react-hot-toast";
import { Suspense } from "react";
import CurrentLocation from "../components/LocationFinder/CurrentLocation";
import Loader from "../components/Loader";
import { Pagecontext } from "../App";
import { useContext } from "react";
import ContactForm from "../components/EbslonLandingPage/ContactForm";
import ThankYouPage from "../components/EbslonLandingPage/ThankYouPage";

import TurningpointCaseStudy from "../components/TurningpointCaseStudy";
import Fintechappdevelopment from "../components/Fintechappdevelopment";
import BestMobileAppDevelopment from "../components/BestMobileAppDevelopment";
import MarketplaceManagement from "../components/MarketplaceManagement";
import WebDesignAgency from "../components/WebDesignAgency";
import ChooseTheBestEcommerce from "../components/ChoosetheBestE-commerce";
import OnlineVisibility2024 from "../components/OnlineVisibility2024";
import CustomCRMDevelopmentCompany from "../components/CustomCRMDevelopmentCompany";
import JobPortalAppDevelopmentCompany from "../components/JobPortalAppDevelopmentCompany";
import SocietyManagementApps2024 from "../components/SocietyManagementApps2024";
import FintechAppDevelopment2024 from "../components/FintechAppDevelopment2024";
import NewProductEcommercePlatform from "../components/NewProductEcommercePlatform";
import HealthTechSolutions from "../components/HealthTechSolutions";
import BestEducationAppDevelopment2024 from "../components/Best-Education-App-Development2024";
import Top10DigitalMarketingStrategies from "../components/Top10DigitalMarketingStrategies";
import TopPredictionsWebDesign2024 from "../components/TopPredictionsWebDesign2024";
import ARandVR from "../components/ARandVR";
import CustomManufacturingCompany from "../components/CustomManufacturingCompany";
import ElevateCustomErpBlog from "../components/ElevateCustomErpBlog";
import UiandUx from "../components/UiandUx";
import AllAboutUIUXDesignBlog from "../components/AllAboutUIUXDesignBlog";
import EbslonNewLandingPage from "../components/EbslonNewLandingPage/EbslonNewLandingPage";
import BestARandVRCompany2024 from "../components/BestARandVRCompany2024";
import RightRetailPosSoftware from "../components/RightRetailPosSoftware";
import BestTravelSoftwareDevelopmentCompany from "../components/BEstTravelSoftwareDevelopmentCompanyJune2024";
import KnowMobileAppDevelopment from "../components/KnowMobileAppDevelopment";
import WhatsappChatbotForBusiness from "../components/WhatsappChatbotForBusiness";
import SupplyChainManagement from "../components/SupplyChainManagement";
import SoftwareDevelopment from "../components/SoftwareDevelopment";
import RightERPVendor from "../components/RightERPVendor";
import RightPlatformForAppDevelopment2024 from "../components/RightPlatformForAppDevelopment2024";
import HiringCustomSoftwareCompanyNov24 from "../components/HiringCustomSoftwareCompanyNov";
import BenefitsOfErpForHealthCareNov21 from "../components/BenefitsOfErpForHealthCareNov21";
import WhyChooseCustomSoftwareBestChoiceNov29 from "../components/WhyChooseCustomSoftwareBestChoiceNov29";
import CRMSoftwareDec10 from "../components/CRMSoftwareDec10";
import Top10EcommerceDec12 from "../components/Top10EcommerceDec12";
import HowUXDesignInfluencesDec20 from "../components/HowUXDesignInfluencesDec20";
import RoleofAugmentedRealityDec26 from "../components/RoleofAugmentedRealityDec26";
import SocietyManagementAppDevelopment from "../components/Integrating-AI-and-IoT-in-Society-Management-App-Development";
import Crmmanagementsoftwaredevelopment from "../components/the-role-of-crm-management-software-development-services";
import MobileAppDevelopment from "../components/why-partnering-with-the–right-mobile-app-development-company-matters";
import SoftwareDevelopmentCompany from "../components/HowToChooseTheBestSoftwareDevelopmentCompanyInIndia";
import HealthtechSoftwareDevelopment from "../components/top-benefits-of-hiring-the-best-healthtech-software-development-company";
import Softwaredevelopmentbusinessesgrow from "../components/how-a-custom-software-development-company-can-help-businesses-grow";
import Whysoftwaredevelopmentservicesmatter from "../components/why-crm-software-development-services-matter-for-success";
import CustomeSoftwareDevelopmentBoootsbusinessEfficiency from "../components/how-custom-software-development-boosts-business-efficiency";
import BestFintechappdevelopment from "../components/best-fintech-app-development-services-for-modern-businesses";
import CustomHealthcareSoftware from "../components/why-custom-healthcare-software-is-key-to-better-patient-care";
import WhyYouNeedACRMSoftwareDevelopmentCompanyToday from "../components/why-you-need-a-crm-software-development-company-today";
import Mobileappdevelopmenteducation from "../components/mobile-app-development-company-for-education-sector-in-india";
import Keytrendsinhealthcaremobileappdevelopmenttowatch from "../components/key-trends-in-healthcare-mobile-app-development-to-watch";
import TheSchoolManagementSoftwareForStudents from "../components/the-benefits-of-using-school-management-software-for-students-teachers-and-parents";

const About = lazy(() => import("../components/About"));
const Blog = lazy(() => import("../components/Blog"));
const BlogDetail = lazy(() => import("../components/BlogDetail"));
const CaseStudies = lazy(() => import("../components/CaseStudies"));
const CaseStudyDetail = lazy(() => import("../components/CaseStudyDetail"));
const Contact = lazy(() => import("../components/Contact"));
const PrivacyPolicy = lazy(() => import("../components/PrivacyPolicy"));
const ReturnRefund = lazy(() => import("../components/ReturnRefund"));
const ServiceDetail = lazy(() => import("../components/ServiceDetail"));
// const Services = lazy(() => import("../components/Services"));
const Solutions = lazy(() => import("../components/Solutions"));
const SolutionsDetail = lazy(() => import("../components/SolutionsDetail"));
const Technologies = lazy(() => import("../components/Technologies"));
const TermCondition = lazy(() => import("../components/TermCondition"));
const TestimonialPartner = lazy(() =>
  import("../components/TestimonialPartner")
);
const Portfolio = lazy(() => import("../components/Portfolio"));
const WorkDetail = lazy(() => import("../components/WorkDetail"));

const Secureecommercewebsite = lazy(() =>
  import("../components/Secureecommercewebsite")
);
const Developingmobileapps = lazy(() =>
  import("../components/Developingmobileapps")
);
const Ecommerceplatformforyourbusiness = lazy(() =>
  import("../components/Ecommerceplatformforyourbusiness")
);
const Iosappperformance = lazy(() => import("../components/Iosappperformance"));
const EcommerceConversionRate = lazy(() =>
  import("../components/EcommerceConversionRate ")
);
const EcommercewebsiteforSEO = lazy(() =>
  import("../components/EcommercewebsiteforSEO")
);
const TheRiseofEcommerce = lazy(() =>
  import("../components/TheRiseofEcommerce")
);
const Custompages = lazy(() => import("../components/Custompages"));
const Edtech = lazy(() => import("../components/Edtech"));
const Ecommerce = lazy(() => import("../components/Ecommerce"));
const TravelAppDevelopment = lazy(() =>
  import("../components/TravelAppDevelopmnt/TravelAppDevelopment")
);
const AppDevelopment = lazy(() => import("../components/AppDevelopment"));
const Jobportal = lazy(() => import("../components/JobPortal"));
const Grocery = lazy(() => import("../components/Grocery"));
const Erpsoftware = lazy(() => import("../components/Erpsoftware"));
const Healthcare = lazy(() => import("../components/Healthcare"));
const PageNotFound = lazy(() => import("../components/PageNotFound"));
const CRM = lazy(() => import("../components/CRM"));
const SchoolManagement = lazy(() => import("../components/SchoolManagement"));
const SocietyManagement = lazy(() => import("../components/SocietyManagement"));
const RealEstate = lazy(() => import("../components/RealEstate"));
const WebDevelopment = lazy(() => import("../components/WebDevelopment"));
const DigitalMarketing = lazy(() => import("../components/DigitalMarketing"));
const WebDesigning = lazy(() => import("../components/WebDesigning"));
const Manufacturing = lazy(() => import("../components/Manufacturing"));
const RetailPos = lazy(() => import("../components/RetailPos"));
const WhatsappBot = lazy(() => import("../components/WhatsappBot"));
const MLandAI = lazy(() => import("../components/MLandAI"));

export default function RoutesPage() {
  const [pageUrl, setPageUrl] = useContext(Pagecontext);

  useEffect(() => {
    console.log(pageUrl, "pageUrl");
  }, [pageUrl]);
  return (
    <>
      {!pageUrl.includes("/ebslon-new-landing-page") &&
        !pageUrl.includes("/software-development-company") &&
        !pageUrl.includes("/ContactForm") &&
        !pageUrl.includes("/ThankYouPage") && <Header />}

      <ScrollToTop />
      <Toaster
        containerStyle={{
          zIndex: "999999999999999999",
        }}
      />
      <Suspense fallback={<Loader />}>
        <CurrentLocation />

        <Routes>
          <Route path="/" exact element={<Index />}></Route>
          <Route path="/about-us" exact element={<About />}></Route>
          <Route path="/About" element={<Navigate to="/about-us" />}></Route>
          {/* <Route path="/Services" exact element={<Services />}></Route> */}
          <Route
            path="/ServiceDetail"
            exact
            element={<ServiceDetail />}
          ></Route>
          <Route path="/blog" exact element={<Blog />}></Route>
          <Route path="/Blog" exact element={<Navigate to="blog" />}></Route>
          <Route path="/BlogDetail" exact element={<BlogDetail />}></Route>
          <Route path="/contact-us" exact element={<Contact />}></Route>
          <Route
            path="/Contact"
            exact
            element={<Navigate to="/contact-us" />}
          ></Route>
          <Route
            path="/TestimonialPartner"
            exact
            element={<TestimonialPartner />}
          ></Route>
          <Route
            path="/PrivacyPolicy"
            exact
            element={<PrivacyPolicy />}
          ></Route>
          <Route
            path="/TermCondition"
            exact
            element={<TermCondition />}
          ></Route>
          <Route path="/ReturnRefund" exact element={<ReturnRefund />}></Route>
          <Route path="/case-studies" exact element={<CaseStudies />}></Route>

          <Route
            path="/CaseStudyDetail"
            exact
            element={<CaseStudyDetail />}
          ></Route>

          <Route path="/technologies" exact element={<Technologies />}></Route>
          <Route
            path="/Technologies"
            exact
            element={<Navigate to="technologies" />}
          ></Route>

          <Route path="/WorkDetail" exact element={<WorkDetail />}></Route>
          <Route path="/portfolio" exact element={<Portfolio />}></Route>
          <Route
            path="/Portfolio"
            exact
            element={<Navigate to="portfolio" />}
          ></Route>
          <Route
            path="/web-design-company-india"
            exact
            element={<WebDesigning />}
          ></Route>
          <Route path="/Solutions" exact element={<Solutions />}></Route>
          <Route
            path="/SolutionsDetail"
            exact
            element={<SolutionsDetail />}
          ></Route>

          <Route
            path="blog/how-custom-software-development-boosts-business-efficiency"
            exact
            element={<CustomeSoftwareDevelopmentBoootsbusinessEfficiency />}
          ></Route>

          <Route
            path="blog/best-fintech-app-development-services-for-modern-businesses"
            exact
            element={<BestFintechappdevelopment />}
          ></Route>
          <Route
            path="blog/why-custom-healthcare-software-is-key-to-better-patient-care"
            exact
            element={<CustomHealthcareSoftware />}
          ></Route>
          <Route
            path="blog/why-you-need-a-crm-software-development-company-today"
            exact
            element={<WhyYouNeedACRMSoftwareDevelopmentCompanyToday />}
          ></Route>
          <Route
            path="blog/mobile-app-development-company-for-education-sector-in-india"
            exact
            element={<Mobileappdevelopmenteducation />}
          ></Route>
          
          
          
          <Route
            path="blog/key-trends-in-healthcare-mobile-app-development-to-watch"
            exact
            element={<Keytrendsinhealthcaremobileappdevelopmenttowatch />}
          ></Route>
        
          <Route
            path="blog/the-benefits-of-using-school-management-software-for-students-teachers-and-parents"
            exact
            element={<TheSchoolManagementSoftwareForStudents />}
          ></Route>





          <Route
            path="blog/top-benefits-of-hiring-the-best-healthtech-software-development-company"
            exact
            element={<HealthtechSoftwareDevelopment />}
          ></Route>

          <Route
            path="blog/how-a-custom-software-development-company-can-help-businesses-grow"
            exact
            element={<Softwaredevelopmentbusinessesgrow />}
          ></Route>

          <Route
            path="blog/why-crm-software-development-services-matter-for-success"
            exact
            element={<Whysoftwaredevelopmentservicesmatter />}
          ></Route>

          <Route
            path="/how-to-secure-your-ecommerce-website-from-cyberattacks"
            exact
            element={<Secureecommercewebsite />}
          ></Route>

          <Route
            path="/the-ultimate-guide-to-developing-mobile-apps-in-2023"
            exact
            element={<Developingmobileapps />}
          ></Route>
          <Route
            path="/how-to-Choose-the-best-e-commerce-platform-for-your-business"
            exact
            element={<Ecommerceplatformforyourbusiness />}
          ></Route>
          <Route
            path="/how-the-new-ios-update-affects-your-app-performance"
            exact
            element={<Iosappperformance />}
          ></Route>
          <Route
            path="/how-to-increase-your-e-commerce-conversion-rate-with-user"
            exact
            element={<EcommerceConversionRate />}
          ></Route>
          <Route
            path="/choosing-the-best-website-development-company-for-your-business"
            exact
            element={<TheRiseofEcommerce />}
          ></Route>
          <Route
            path="/which-is-the-best-mobile-app-development-company-in-delhi-ncr"
            exact
            element={<BestMobileAppDevelopment />}
          ></Route>
          <Route
            path="/how-to-find-the-right-web-design-agency-for-your-website-development"
            exact
            element={<WebDesignAgency />}
          ></Route>
          <Route
            path="/how-to-optimize-your-ecommerce-website-for-seo"
            exact
            element={<EcommercewebsiteforSEO />}
          ></Route>
          <Route
            path="/choose-the-best-e-commerce-development-company-for-your-business"
            exact
            element={<ChooseTheBestEcommerce />}
          ></Route>
          <Route
            path="/best-digital-marketing-agency-to-increase-your-online-visibility-in-2024"
            exact
            element={<OnlineVisibility2024 />}
          ></Route>
          <Route
            path="/find-the-top-notch-custom-crm-development-company-delhi-india"
            exact
            element={<CustomCRMDevelopmentCompany />}
          ></Route>
          <Route
            path="/benefits-of-hiring-a-job-portal-app-development-company"
            exact
            element={<JobPortalAppDevelopmentCompany />}
          ></Route>
          <Route
            path="/top-10-features-for-society-management-apps-in-2024"
            exact
            element={<SocietyManagementApps2024 />}
          ></Route>
          <Route
            path="/finding-the-right-fintech-app-development-for-your-startup-hire-fintech-app-developer"
            exact
            element={<FintechAppDevelopment2024 />}
          ></Route>
          <Route
            path="/an-ultimate-guide-to-new-product-development-for-e-commerce-platforms"
            exact
            element={<NewProductEcommercePlatform />}
          ></Route>
          <Route
            path="/healthtech-solutions-custom-healthcare-software-and-app-development-company"
            exact
            element={<HealthTechSolutions />}
          ></Route>
          <Route
            path="/unlock-the-best-education-app-development-company-in-delhi-ncr"
            exact
            element={<BestEducationAppDevelopment2024 />}
          ></Route>
          <Route
            path="/top-10-digital-marketing-strategies-to-boost-your-business-by-ebslon-infotech"
            exact
            element={<Top10DigitalMarketingStrategies />}
          ></Route>
          <Route
            path="/creative-web-design-top-predictions-for-web-design-in-2024-by-ebslon-infotech"
            exact
            element={<TopPredictionsWebDesign2024 />}
          ></Route>
          <Route
            path="/hiretop-custom-manufacturing-software-development-company"
            exact
            element={<CustomManufacturingCompany />}
          ></Route>

          <Route
            path="/elevate-your-brand-with-custom-erp-development-company"
            exact
            element={<ElevateCustomErpBlog />}
          ></Route>
          <Route
            path="/Integrating-AI-and-IoT-in-Society-Management-App-Development"
            exact
            element={<SocietyManagementAppDevelopment />}
          ></Route>
          <Route
            path="/blog/the-role-of-crm-management-software-development-services"
            exact
            element={<Crmmanagementsoftwaredevelopment />}
          ></Route>

          <Route
            path="/blog/why-partnering-with-the–right-mobile-app-development-company-matters"
            exact
            element={<MobileAppDevelopment />}
          ></Route>

          <Route
            path="/blog/how-to-choose-the-best-software-development-company-in-india"
            exact
            element={<SoftwareDevelopmentCompany />}
          ></Route>

          <Route
            path="/best-augmented-reality-and-virtual-reality-company-india-2024"
            exact
            element={<BestARandVRCompany2024 />}
          ></Route>
          <Route
            path="/all-about-ui-ux-design-understanding-the-basics-for-beginner"
            exact
            element={<AllAboutUIUXDesignBlog />}
          ></Route>
          <Route
            path="/choose-the-right-retail-pos-software-development-company"
            exact
            element={<RightRetailPosSoftware />}
          ></Route>
          <Route
            path="/best-travel-software-development-company-with-industry-expertise-in-2024"
            exact
            element={<BestTravelSoftwareDevelopmentCompany />}
          ></Route>
          <Route
            path="/know-the-importance-of-mobile-app-development-services-for-your-brand"
            exact
            element={<KnowMobileAppDevelopment />}
          ></Route>
          <Route
            path="/whatsapp-chatbot-for-business-how-does-it-work-and-why-do-you"
            exact
            element={<WhatsappChatbotForBusiness />}
          ></Route>
          <Route
            path="/how-to-choose-the-right-erp-vendor-for-your-business-needs"
            exact
            element={<RightERPVendor />}
          ></Route>
          <Route
            path="/how-to-choose-the-right-platform-for-app-development"
            exact
            element={<RightPlatformForAppDevelopment2024 />}
          ></Route>
          <Route
            path="/5-tips-for-hiring-a-custom-software-development-company"
            exact
            element={<HiringCustomSoftwareCompanyNov24 />}
          ></Route>
          <Route
            path="/top-5-benefits-of-erp-software-for-the-healthcare-industry"
            exact
            element={<BenefitsOfErpForHealthCareNov21 />}
          ></Route>
          <Route
            path="/why-custom-software-development-is-the-best-choice-for-your-business"
            exact
            element={<WhyChooseCustomSoftwareBestChoiceNov29 />}
          ></Route>
          <Route
            path="/how-crm-software-can-simplify-lead-management-for-your-business"
            exact
            element={<CRMSoftwareDec10 />}
          ></Route>
          <Route
            path="/top-10-ecommerce-website-development-mistakes-to-avoid"
            exact
            element={<Top10EcommerceDec12 />}
          ></Route>
          <Route
            path="/how-ux-design-influences-product-success-in-competitive-markets"
            exact
            element={<HowUXDesignInfluencesDec20 />}
          ></Route>
          <Route
            path="/the-role-of-augmented-reality-ar-in-mobile-app-development"
            exact
            element={<RoleofAugmentedRealityDec26 />}
          ></Route>

          <Route path="/Custompages" exact element={<Custompages />}></Route>
          {/* <Route path="/technologies" exact element={<Technologies />}></Route> */}
          <Route
            path="/educational-app-development"
            exact
            element={<Edtech />}
          ></Route>
          <Route
            path="/ecommerce-app-development"
            exact
            element={<Ecommerce />}
          ></Route>
          <Route
            path="/travel-app-development"
            exact
            element={<TravelAppDevelopment />}
          ></Route>

          <Route
            path="/fintech-app-development-company"
            exact
            element={<Fintechappdevelopment />}
          ></Route>

          <Route
            path="/job-portal-app-development"
            exact
            element={<Jobportal />}
          ></Route>
          <Route
            path="/grocery-app-development"
            exact
            element={<Grocery />}
          ></Route>
          <Route
            path="/erp-software"
            element={<Navigate to="/custom-erp-software-development-company" />}
          ></Route>
          <Route
            path="/custom-erp-software-development-company"
            exact
            element={<Erpsoftware />}
          ></Route>
          <Route
            path="/Healthcare"
            element={<Navigate to="/healthcare-software-development" />}
          ></Route>
          <Route
            path="/healthcare-software-development"
            exact
            element={<Healthcare />}
          ></Route>
          <Route
            path="/CRM"
            element={<Navigate to="/custom-crm-development-services" />}
          ></Route>
          <Route
            path="/custom-crm-development-services"
            exact
            element={<CRM />}
          ></Route>
          <Route
            path="/SchoolManagement"
            element={
              <Navigate to="/school-management-software-development-company" />
            }
          ></Route>
          <Route
            path="/school-management-software-development-company"
            exact
            element={<SchoolManagement />}
          ></Route>
          <Route
            path="/SocietyManagement"
            element={<Navigate to="/society-management-app-development" />}
          ></Route>
          <Route
            path="/society-management-app-development"
            exact
            element={<SocietyManagement />}
          ></Route>
          <Route
            path="/real-estate-app-development"
            exact
            element={<RealEstate />}
          ></Route>
          <Route
            path="/RealEstate"
            element={<Navigate to="/real-estate-app-development" />}
          ></Route>
          <Route
            path="/web-design-company-india"
            exact
            element={<WebDevelopment />}
          ></Route>
          <Route
            path="/digital-marketing-agency"
            exact
            element={<DigitalMarketing />}
          ></Route>
          <Route
            path="/DigitalMarketing"
            element={<Navigate to="/digital-marketing-agency" />}
          ></Route>
          <Route
            path="/MarketplaceManagement"
            exact
            element={<MarketplaceManagement />}
          ></Route>
          <Route
            path="/MarketplaceManagement"
            element={<Navigate to="/marketplace-management" />}
          ></Route>

          <Route
            path="/website-designing-company"
            exact
            element={<WebDesigning />}
          ></Route>
          <Route
            path="/Manufacturing"
            element={<Navigate to="/manufacturing-software-development" />}
          ></Route>
          <Route
            path="/manufacturing-software-development"
            exact
            element={<Manufacturing />}
          ></Route>
          <Route
            path="/RetailPOS"
            element={<Navigate to="/retail-pos-software" />}
          ></Route>
          <Route
            path="/retail-pos-software"
            exact
            element={<RetailPos />}
          ></Route>
          <Route
            path="/WhatsappBot"
            element={<Navigate to="/whatsapp-bot" />}
          ></Route>
          <Route path="/whatsapp-bot" exact element={<WhatsappBot />}></Route>
          <Route
            path="/FastoCaseStudy"
            exact
            element={<FastoCaseStudy />}
          ></Route>
          <Route
            path="/HuddleHeartCaseStudy"
            exact
            element={<HuddleHeartCaseStudy />}
          ></Route>
          <Route
            path="/PlywoodBazarCaseStudy"
            exact
            element={<PlywoodBazarCaseStudy />}
          ></Route>
          <Route
            path="/ROCareIndiaCaseStudy"
            exact
            element={<ROCareIndiaCaseStudy />}
          ></Route>
          <Route
            path="/NikkyPoreCaseStudy"
            exact
            element={<NikkyPoreCaseStudy />}
          ></Route>
          <Route
            path="/DeyorCaseStudy"
            exact
            element={<DeyorCaseStudy />}
          ></Route>
          <Route
            path="/SamagriCaseStudy"
            exact
            element={<SamagriCaseStudy />}
          ></Route>
          <Route
            path="/TurningPointCaseStudy"
            exact
            element={<TurningpointCaseStudy />}
          ></Route>
          <Route
            path="/ai-ml-development-services-company"
            exact
            element={<MLandAI />}
          ></Route>
          <Route
            path="/ar-vr-app-development"
            exact
            element={<ARandVR />}
          ></Route>
          <Route
            path="/web-development-company-in-india"
            element={<WebDevelopment />}
          ></Route>
          <Route
            path="/WebAppDevelopment"
            exact
            element={<Navigate to="/web-development-company-in-india" />}
          ></Route>
          <Route
            path="/custom-software-development"
            exact
            element={<SoftwareDevelopment />}
          ></Route>
          <Route
            path="/supply-chain-management"
            exact
            element={<SupplyChainManagement />}
          ></Route>
          <Route
            path="/ui-ux-design-company-india"
            exact
            element={<UiandUx />}
          ></Route>
          <Route
            path="/ui-and-ux-design"
            element={<Navigate to="/ui-ux-design-company-india" />}
          ></Route>
          <Route
            path="/software-development-company"
            exact
            element={<EbslonLandingPageUpdate />}
          ></Route>

          {/* <Route
            path="/ebslon-new-landing-page"
            exact
            element={<EbslonNewLandingPage />}
          ></Route> */}

          <Route
            path="/mobile-app-development-company"
            exact
            element={<AppDevelopment />}
          ></Route>
          <Route
            path="/app-development-company"
            element={<Navigate to="/mobile-app-development-company" />}
          ></Route>
          <Route path="/ContactForm" exact element={<ContactForm />}></Route>
          <Route path="/ThankYouPage" exact element={<ThankYouPage />}></Route>

          <Route path="*" exact element={<PageNotFound />}></Route>
        </Routes>
      </Suspense>

      {!pageUrl.includes("/ebslon-new-landing-page") &&
        !pageUrl.includes("/software-development-company") &&
        !pageUrl.includes("/ContactForm") &&
        !pageUrl.includes("/ThankYouPage") && <Footer />}
    </>
  );
}
